import React from 'react';
import SEO from 'components/seo';


function ICAC() {
  return (
    <>
      <SEO
        title="ICAC/KAEB"
        ogUrl="https://emvelia.com/case-studies/information-communication-assistance-center-kaeb"
        ogImg="/assets/what-we-do/icac.jpg"
      />

      <header
        className="text-center text-white position-relative py-4 mb-4"
        style={{
          background: '#000 url("/assets/case-studies/icac-kaeb/header.jpeg") no-repeat center/cover',
        }}
      >
        <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', top: 0, left: 0, right: 0, bottom: 0, position: 'absolute', zIndex: -1 }} />
        <h1 className="font-weight-bolder underscore">Emvelia Technologies Case Study</h1>
        <h2>Digital Transformation in Care at Home for Municipalities in the EU</h2>
        <h3 className="font-weight-light">Immediate Communication & Assistance Centre (I.C.A.C.)<br />
          <span className="font-italic">Κέντρο Άμεσης Επικοινωνίας και Βοηθείας (ΚΑΕΒ.)</span>
        </h3>
      </header>

      <div className="container-fluid display-6 mb-6">
        <div className="row mb-6 align-items-center justify-content-center">
          <div className="col text-right">
            <img src="/assets/case-studies/icac-kaeb/gr-ministry-digital-governance.png" className="img-fluid" alt=" " />
          </div>
          <div className="col text-center">
            <img src="/assets/case-studies/icac-kaeb/gr-at-ac.gif" className="img-fluid" alt=" " />
          </div>
          <div className="col">
            <img src="/assets/case-studies/icac-kaeb/difa.png" className="img-fluid" alt=" " />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h2>Program & Funding</h2>
            <ul>
              <li>Funded by the Hellenic Ministry of Digital Governance</li>
              <li>Financed for the Municipality of Acharnes, Athens, Greece</li>
              <li>Q4 2021 Pilot for DI.F.A., the Municipal Care of Acharnes</li>
              <li>Developed by Emvelia Technologies; Analyst & Coordinator Dr. Pantelis Kotatis</li>
            </ul>

            <h2>Objectives</h2>
            <ul>
              <li>Digital Transformation in Care at home for Municipality Care Services in the EU</li>
              <li>Digitalise patients’ medical and personal information</li>
              <li>Optimise care response times</li>
              <li>Provide personalised care services</li>
            </ul>

            <h2>Municipality of Acharnes, Athens, Greece</h2>

            <p>The Municipality of Acharnes is in the top 10 of most populated municipalities in Greece with about 100,000 people and siting in the suburbs of Athens.</p>

            <p>The Municipality of Acharnes provides care services to local residents through organisations such as DI.F.A. known as the Municipal Care of Acharnes. The purposes of DI.F.A. are:</p>
            <ol>
              <li>The provision of services to the Elderly for the prevention of biological, psychological and social problems.</li>
              <li>The provision of services to preschool children. DI.F.A. takes care of the proper operation of the Municipal Kindergartens and Elementary schools and the daily nutrition, education and entertainment of the children, so that they develop comprehensively physically, mentally, emotionally and socially.</li>
              <li>Elaborating on sports, cultural, social and environmental programs for the development of physical, spiritual, moral and aesthetic education of the inhabitants, the promotion and protection of local culture, the protection of vulnerable social groups and the protection and upgrading of the environment, respectively.</li>
            </ol>

            <h2>From Old to New</h2>
            <p>
              Providing care at home, means care staff will visit a patient’s home when they believe a visit is needed, either by appointment or at regular intervals as per the patient’s schedule. The carer would perform their duty, take notes and organise any future visits.
            </p>

            <p>There are many optimisation issues with this, as 1) the patient might not remember they are being paid a visit, 2) they might forget any medicine they need to take or 3) they simply don’t need someone to come help them. These are some typical issues a municipal care unit faces, which can lead to severely unoptimised processes, patient intake and care irregularities, and in most cases a carer spending time that could otherwise be spent with patients in need.</p>

            <p>I.C.A.C. (or Κ.Α.Ε.Β. in Greek) has transformed these processes into a digital ecosystem that is optimised, accurate, transparent, secure and provides patients, carers and the municipality better results in real-time.</p>

            <p>Carers now have complete medical profiles of their patients, with current and historical data, in a platform that allows them to easily manage the care provided in real-time. Teams and managers can view where any patient location when an SOS call is placed, what the active biometrics are, and speak to their patient in real-time through Wearable technology.</p>

            <p>Carers now rely on a Real-Time response system that will remind patients when to take their medicine, provide 24/7 support to them through a call centre and save time visiting patients at home. The system allows carers to intervene by calling their patients directly on their wearable device in case they identify high or low pressure, heart rate, oxygen or in case a patient falls. GPS technology will indicate the live patient's exact position in case they need an ambulance.</p>

            <p>
              The Municipality of Acharnes received 102 wearable devices, 6 tablets for carers and a central monitoring unit for managers and is now extending the service for more. The system is built to save lives of citizens and in most cases before it is too late to intervene.
            </p>


            <h2>Successful Transformation in Care at Home</h2>
            <p>President of DI.F.A. and Municipal Advisor Zachariadi Aggeliki said with the operation of the "Immediate Communication and Assistance Centre", the "Care at Home" and in general the social welfare services offered by the Municipality of Acharnes to the citizens achieve:</p>
            <ul>
              <li>More efficient emergency and scheduled service</li>
              <li>Fully automated and faster notification for help and communication</li>
              <li>Better use of available resources (vehicles, staff, structures)</li>
              <li>Upgrading the quality of services through the utilization of modern technologies, Telematics, Telephony and Geoinformatics.</li>
              <li>Security of personal data and information circulated between K.A.E.B. and its departments.</li>
            </ul>

            <p>The action of the pilot project was funded exclusively by the Hellenic Ministry of Digital Governance. It included 90 wearable devices and 12 devices for members of staff. The pilot consisted of three parts:</p>
            <ol>
              <li>Creation of the K.A.E.B. Centre</li>
              <li>Creation of an integrated information system platform and</li>
              <li>Staff Training</li>
            </ol>

            <p>After the successful completion of the first pilot, the Mayor of Acharnes Spyros Vrettos signed the first extension of the program in Q4, 2021, which supports Preventive Medicine and 24/7 support services.</p>
          </div>
        </div>

        <div className="row justify-content-center mb-4">
          <div className="col-md-12 col-lg-7 d-flex mb-4 mb-lg-0">
            <img src="/assets/case-studies/icac-kaeb/team.jpeg" className="img-fluid mx-auto img-thumbnail" alt=" " style={{ objectFit: 'cover' }} />
          </div>
          <div className="col-md-12 col-lg-5 d-flex">
            <img src="/assets/case-studies/icac-kaeb/team2.jpeg" className="img-fluid mx-auto img-thumbnail" alt=" " style={{ objectFit: 'cover' }} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <b>Resources:</b><br />
            <ul>
              <li><a href="https://www.difa.gr/?p=3003" target="_blank">https://www.difa.gr/?p=3003</a></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default ICAC;
